import React from 'react';
import css from './assets/styles/HowCoopWorks.module.css';

const HowCoopWorks = () => {
  const steps = [
    {
      id: 1,
      title: 'Create Your Account',
      description:
        'Sign up by completing the registration form and submitting proof of identity documents. Once approved by our team, your account will be activated, and you’ll be ready to start transacting seamlessly!',
    },
    {
      id: 2,
      title: 'Rent or List Vehicles',
      description: 'You can use the platform to find the equipment you need or list idle ones in your fleet. All of this can be done directly from a mobile device or desktop computer.',
    },
    {
      id: 3,
      title: 'Pick-Up',
      description:
        'Once a reservation is set to begin, both the equipment owner and the driver using the rental will need to upload photos of the vehicle’s condition on the platform to start a rental and get on the road.',
    },
    {
      id: 4,
      title: 'Drop-Off',
      description:
        'When equipment is returned to its designated location, a reservation can be completed by handing off the keys, documenting some information, and uploading some return pictures.',
    },
  ];

  return (
    <div className={css.howCoopWorksContainer}>
      <div className={css.howCoopWorksWrapper}>
        <h2>How Leasedout works</h2>
        <p className={css.headerText}>
        With thousands of dry vans, refrigerated, flatbed trailers, car haulers, box trucks, and cargo vans, our platform makes it easy for businesses to rent out idle equipment. Our process:
        </p>
        <div className={css.steps}>
          {steps.map(step => (
            <div key={step.id} className={css.step}>
              <div className={css.stepWrapper}>
                <div className={css.stepCircle}>{step.id}</div>
                <div className={css.stepperMarker}></div>
              </div>
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowCoopWorks;
