import React, { useState } from "react";
import css from "./assets/styles/FAQs.module.css";

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    { question: "What’s required to rent the equipment on the platform?", answer: "To qualify for an account and rent on LeasedOUT, all individuals or businesses must provide proof of identification, proof of insurance, and have an active USDOT number." },
    { question: "What types of Equipment can be listed to rent out?", answer: "Currently we accept trailers, box trucks, and cargo van." },
    { question: "Do I need to provide my own insurance?", answer: "Yes, you need to provide proof of insurance" },
    { question: "Does Leasedout offer delivery or transfer for trailers?", answer: "No, we do not offer delivery for the equipment on our platform" },
    { question: "How does vehicle pick-up and drop-off work?", answer: "Completing vehicle pick up and drop off is required for every rental. At the time of pick up and drop off, your assigned driver(s) will receive a text. It will include step-by-step instructions and a link to upload photos of the equipment to document its condition, if applicable mileage, fuel level, and refrigeration hours. Owners are also required to submit photos before and after a rental." },
    { question: "What methods of payment are accepted by LeasedOUT?", answer: "LeasedOUT accepts all major credit cards for rental payments." },
    { question: "Is it free to list equipment for rent on LeaseOUT?", answer: "yes, you can list unlimited equipment for rent" },
    { question: "Do I have to pay a deposit to rent an equipment?", answer: "Yes a deposit is required to rent the equipment on our platform.. The deposit will be held by Stripe (the processor) until the trailer is returned. If the trailer is returned in the same condition as when it was picked up and there are no other discrepancies, you will receive the full deposit back." },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className={css.faqsContainer}>
      <h2>Popular FAQs</h2>
      <div className={css.faqsList}>
        {faqs.map((faq, index) => (
          <div key={index} className={css.faqItem}>
            <div
              className={css.faqQuestion}
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
              <span className={`${css.faqToggle} ${activeIndex === index ? css.open : ""}`}>+</span>
            </div>
            {activeIndex === index && (
              <div className={css.faqAnswer}>
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
      <button className="primaryButton">See All FAQs</button>
    </div>
  );
};

export default FAQs;
