import React from "react";
import css from "./assets/styles/EarnMoney.module.css";
import EarnMoney1 from "./assets/images/owner1.png";
import EarnMoney2 from "./assets/images/owner2.png";
import EarnMoney3 from "./assets/images/owner3.png";
import EarnMoney4 from "./assets/images/owner4.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useRouteConfiguration } from "../../context/routeConfigurationContext";
import { findRouteByRouteName } from "../../util/routes";
const EarnMoney = () => {
  const history = useHistory()
  const images = [
    EarnMoney1,
    EarnMoney2,
    EarnMoney3,
    EarnMoney4
  ];
  const routeConfiguration = useRouteConfiguration();
  const newListingPath = findRouteByRouteName("NewListingPage", routeConfiguration)?.path
  return (
    <div className={css.earnMoneyWrapper}>
    <div className={css.earnMoneyContainer}>
      <div className={css.earnMoneyText}>
        <p className={css.subheading}>FOR EQUIPMENT OWNERS</p>
        <h1>Earn money from your idle equipment.</h1>
        <ul>
          <li>Lease to verified guest trucking companies.</li>
          <li>List for free and earn thousands per year.</li>
          <li>Stay 100% in control of your property.</li>
        </ul>
        <button className={'primaryButton'} onClick={()=>history.push(newListingPath)}>Start my Listing</button>
      </div>
      <div className={css.earnMoneyImages}>
        {images.map((img, index) => (
          <img key={index} src={img} alt={`Gallery ${index + 1}`} />
        ))}
      </div>
    </div>
    </div>
  );
};

export default EarnMoney;
