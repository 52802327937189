import React, { useEffect, useState } from 'react';
import css from './assets/styles/CustomLandingPage.module.css';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import EquipmentByState from './EquipmentByState';
import WhoIsCoop from './WhoIsCoop';
import HowCoopWorks from './HowCoopWorks';
import EarnMoney from './EarnMoney';
import FAQs from './FAQs';
// import VehicleTypes from './VehicleTypes';
import TopbarSearchForm from '../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import loadable from '@loadable/component';
import { createResourceLocatorString } from '../../util/routes';
import { useDispatch } from 'react-redux';
import { showListing } from './CustomLandingPage.duck';



import TruckImg from './assets/images/v1.png';
import CargoImg from './assets/images/v2.png';
import TrailerImg from './assets/images/v3.png';
import FooterContainer from '../FooterContainer/FooterContainer';


const VehicleTypes = loadable(() => import('./VehicleTypes'), {
  ssr: false,
  fallback: <p>loading..............</p>
});
const CustomLandingPage = (props) => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();
  const [searchingTab, setSearchingTab] = useState("trailers")
  const [listingData, setListingData] = useState([])
  const [listingDataAll, setListingDataAll] = useState([]);
  const [toogleClass, setToggleClass] = useState("anywhere");

  const toggleActive = () => {
    setInterval(() => {
      setToggleClass(prevClass => prevClass === "anywhere" ? "anytime" : "anywhere");
    }, 2000);
  };

  useEffect(() => {
    toggleActive();
  }, [])

  function handleSubmit(values) {
    const { currentSearchParams } = props;
    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};
      const categoryId = searchingTab === "trailers" ? "Trailers-id" : searchingTab === "boxtruck" ? "Boxtrucks-id" : searchingTab === "cargovan" ? "cargovans-id" : null
      return {
        ...originMaybe,
        address: search,
        pub_categoryLevel1:categoryId,
          bounds,
      };
    };
    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(showListing()).then((data) => {
      const sliderData = []
      data?.data?.map((listing) => {

        const title = listing?.attributes.title
        const imageId = listing?.relationships?.images.data[0].id.uuid
        const imageData = data.included?.find(singleImageData => singleImageData.id.uuid === imageId)
        console.log("imageData?.attributes?.variants", imageData?.attributes?.variants)
        sliderData?.push({ image: imageData?.attributes?.variants['scaled-medium'].url, title: title })

      })
      setListingDataAll(data.data)
      setListingData(sliderData)
    })
  }, [])

  return (
    <div className={css.landingPageContainer}>
      <Page title={''} scrollingDisabled={false}>
        <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
          <div className={css.landingPage}>
            <div className={css.sectionWrapper}>
              <div>
                <header className={`${css.header} header`}>
                  <h1>The equipment you need,
                    <span class={css.taglineText}><span class={toogleClass === "anywhere" ? css.active : ""}> Anywhere</span><span class={toogleClass === "anytime" ? css.active : ""}>Anytime</span></span>.</h1>
                  <p>Find, Request and Book your next Equipment in minutes</p>
                </header>

                <section className={css.categories}>
                  <div className={`${css.category} ${searchingTab == "trailers" ? css.selected : ""}`} onClick={() => { setSearchingTab("trailers") }}>
                    {/* <TrailerIcon /> */}
                    <img className={css.iconSearch} src={TrailerImg} />


                    <span>Trailers</span>
                  </div>
                  <div className={`${css.category} ${searchingTab == "boxtruck" ? css.selected : ""}`} onClick={() => { setSearchingTab("boxtruck") }}>
                    {/* <BoxTruckIcon /> */}
                    <img className={css.iconSearch} src={TruckImg} />
                    <span>Box trucks</span>
                  </div>
                  <div className={`${css.category} ${searchingTab == "cargovan" ? css.selected : ""}`} onClick={() => { setSearchingTab("cargovan") }}>
                    {/* <CargoVanIcon /> */}
                    <img className={css.iconSearch} src={CargoImg} />


                    <span>Cargo vans</span>
                  </div>
                </section>

                <section className={css.searchBar}>
                  <TopbarSearchForm
                    className={css.custom}
                    desktopInputRoot={"testing"}
                    onSubmit={handleSubmit}
                    initialValues={{ location: null }}
                    appConfig={config}
                    PageName={"home"}
                  />
                  {/* <div className="search-input" onClick={()=> history.push("/s")}>
                    <SearchIcon />
                    <input type="text" placeholder="Search by city, State, or Listing" />
                  </div> */}
                </section>
              </div>
            </div>
            <div className={css.footer}>
              <p className={css.contentText}>
                The LeasedOUT platform offers access to more than{' '}
                <strong>1,000 commercial equipment in the United States</strong>
              </p>
            </div>
          </div>
          <VehicleTypes listingData={listingData} />
          <EquipmentByState listingDataAll={listingDataAll} />
          <WhoIsCoop />
          <HowCoopWorks />
          <EarnMoney />
          <FAQs />
        </LayoutSingleColumn>
      </Page>
    </div>
  );
};
export default CustomLandingPage;
