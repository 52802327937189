import React from 'react';
import DeviceImage from './assets/images/leasedoutDevice1.png';
import css from "./assets/styles/WhoIsCoop.module.css"


const WhoIsCoop = () => {
  return (
    <div className={css.whoIsCopperWrapper}>
      <div className={css.whoIsCoopContainer}>
        <div className={css.leftContainer}>
          <h2>Who is LeasedOUT?</h2>
          <p>
            LeasedOut is the platform that puts idle equipment to work. Whether you're looking to rent or list unused trailers, Box trucks, or vans, we help trucking businesses maximize the value of their assets. LeasedOut transforms idle time into opportunity, boosting efficiency and generating revenue for your fleet.
          </p>
        </div>
        <div className={css.rightCOntainer}>
          <img src={DeviceImage} />
        </div>
      </div>
    </div>
  );
};

export default WhoIsCoop;
