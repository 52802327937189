import React, { useEffect, useState } from 'react';
import css from './assets/styles/EquipmentByState.module.css';
import { NamedLink } from '../../components';
import { stringify } from 'query-string';

// const statesData = [
//   { state: 'Alabama', available: 7 },
//   { state: 'Alaska', available: 6 },
//   { state: 'Arizona', available: 17 },
//   { state: 'Arkansas', available: 30 },
//   { state: 'California', available: 13 },
//   { state: 'Colorado', available: 54 },
//   { state: 'Florida', available: 14 },
//   { state: 'Georgia', available: 9 },
//   { state: 'Idaho', available: 35 },
//   { state: 'Illinois', available: 19 },
//   { state: 'Indiana', available: 11 },
//   { state: 'Iowa', available: 126 },
//   { state: 'Kansas', available: 383 },
//   { state: 'Kentucky', available: 26 },
//   { state: 'Louisiana', available: 11 },
//   { state: 'Maryland', available: 2 },
//   { state: 'Michigan', available: 7 },
//   { state: 'Minnesota', available: 30 },
//   { state: 'Mississippi', available: 40 },
//   { state: 'Missouri', available: 147 },
//   { state: 'Montana', available: 767 },
//   { state: 'Nebraska', available: 408 },
//   { state: 'New Jersey', available: 1 },
//   { state: 'New Mexico', available: 12 },
//   { state: 'New York', available: 21 },
//   { state: 'North Carolina', available: 13 },
//   { state: 'North Dakota', available: 185 },
//   { state: 'Ohio', available: 41 },
//   { state: 'Oklahoma', available: 37 },
//   { state: 'Oregon', available: 4 },
//   { state: 'Pennsylvania', available: 26 },
//   { state: 'South Carolina', available: 8 },
//   { state: 'South Dakota', available: 56 },
//   { state: 'Tennessee', available: 41 },
//   { state: 'Texas', available: 48 },
//   { state: 'Utah', available: 1 },
//   { state: 'Vermont', available: 2 },
//   { state: 'Virginia', available: 17 },
//   { state: 'Washington', available: 35 },
//   { state: 'Wisconsin', available: 72 },
//   { state: 'Wyoming', available: 29 },
// ];


const statesData = [
  { state: 'Alabama', available: 7, bound: '35.0007%2C-84.8882%2C30.1944%2C-88.4732' },
  { state: 'Alaska', available: 6, bound: '71.5388%2C-129.9795%2C51.2142%2C172.4631' },
  { state: 'Arizona', available: 17, bound: '37.0043%2C-109.0452%2C31.3322%2C-114.8183' },
  { state: 'Arkansas', available: 30, bound: '36.4996%2C-89.6444%2C33.0041%2C-94.6179' },
  { state: 'California', available: 13, bound: '42.0095%2C-114.1312%2C32.5342%2C-124.4096' },
  { state: 'Colorado', available: 54, bound: '41.0034%2C-102.0415%2C36.9931%2C-109.0452' },
  { state: 'Florida', available: 14, bound: '31.0009%2C-80.0314%2C24.3963%2C-87.6349' },
  { state: 'Georgia', available: 9, bound: '35.0007%2C-80.8397%2C30.3568%2C-85.6052' },
  { state: 'Idaho', available: 35, bound: '49.0000%2C-111.0435%2C42.0000%2C-117.2430' },
  { state: 'Illinois', available: 19, bound: '42.5083%2C-87.4948%2C36.9703%2C-91.5121' },
  { state: 'Indiana', available: 11, bound: '41.7606%2C-84.7846%2C37.7717%2C-88.0994' },
  { state: 'Iowa', available: 126, bound: '43.5014%2C-90.1401%2C40.3754%2C-96.6395' },
  { state: 'Kansas', available: 383, bound: '40.0031%2C-94.5884%2C36.9931%2C-102.0517' },
  { state: 'Kentucky', available: 26, bound: '39.1475%2C-81.9649%2C36.4971%2C-89.5715' },
  { state: 'Louisiana', available: 11, bound: '33.0195%2C-88.8170%2C28.9286%2C-94.0431' },
  { state: 'Maryland', available: 2, bound: '39.7230%2C-75.0489%2C37.9117%2C-79.4876' },
  { state: 'Michigan', available: 7, bound: '48.2388%2C-82.4112%2C41.6961%2C-90.4181' },
  { state: 'Minnesota', available: 30, bound: '49.3844%2C-89.4861%2C43.4994%2C-97.2392' },
  { state: 'Mississippi', available: 40, bound: '35.0008%2C-88.0987%2C30.1739%2C-91.6550' },
  { state: 'Missouri', available: 147, bound: '40.6136%2C-89.0987%2C35.9957%2C-95.7748' },
  { state: 'Montana', available: 767, bound: '49.0000%2C-104.0391%2C44.3582%2C-116.0498' },
  { state: 'Nebraska', available: 408, bound: '43.0017%2C-95.3080%2C39.9999%2C-104.0535' },
  { state: 'New Jersey', available: 1, bound: '41.3574%2C-73.8937%2C38.9286%2C-75.5636' },
  { state: 'New Mexico', available: 12, bound: '37.0002%2C-103.0019%2C31.3322%2C-109.0452' },
  { state: 'New York', available: 21, bound: '45.0159%2C-71.8562%2C40.4961%2C-79.7624' },
  { state: 'North Carolina', available: 13, bound: '36.5881%2C-75.4606%2C33.8423%2C-84.3219' },
  { state: 'North Dakota', available: 185, bound: '49.0000%2C-96.5545%2C45.9351%2C-104.0452' },
  { state: 'Ohio', available: 41, bound: '41.9773%2C-80.5199%2C38.4034%2C-84.8203' },
  { state: 'Oklahoma', available: 37, bound: '37.0023%2C-94.4306%2C33.6158%2C-103.0026' },
  { state: 'Oregon', available: 4, bound: '46.2920%2C-116.4632%2C41.9918%2C-124.5662' },
  { state: 'Pennsylvania', available: 26, bound: '42.2698%2C-74.6895%2C39.7198%2C-80.5199' },
  { state: 'South Carolina', available: 8, bound: '35.2154%2C-78.5426%2C32.0335%2C-83.3539' },
  { state: 'South Dakota', available: 56, bound: '45.9455%2C-96.4397%2C42.4799%2C-104.0577' },
  { state: 'Tennessee', available: 41, bound: '36.6781%2C-81.6469%2C34.9829%2C-90.3102' },
  { state: 'Texas', available: 48, bound: '36.5007%2C-93.5085%2C25.8371%2C-106.6456' },
  { state: 'Utah', available: 1, bound: '42.0003%2C-109.0410%2C36.9987%2C-114.0529' },
  { state: 'Vermont', available: 2, bound: '45.0054%2C-71.4988%2C42.7269%2C-73.4363' },
  { state: 'Virginia', available: 17, bound: '39.4660%2C-75.2438%2C36.5408%2C-83.6754' },
  { state: 'Washington', available: 35, bound: '49.0025%2C-116.9160%2C45.5435%2C-124.8484' },
  { state: 'Wisconsin', available: 72, bound: '47.3098%2C-86.2495%2C42.4919%2C-92.8881' },
  { state: 'Wyoming', available: 29, bound: '45.0021%2C-104.0553%2C40.9945%2C-111.0569' },
];
const EquipmentByState = ({ listingDataAll }) => {
  const [states, setStates] = useState([])

  useEffect(() => {
    const stateData = statesData?.map(stateData => {
      const countListing = listingDataAll?.filter(listing => {
        const address = listing.attributes?.publicData?.location?.address || "";
        const addressParts = address.split(',').map(part => part.trim()); // Split and trim each part
        const stateCandidate = addressParts.length > 1 ? addressParts[addressParts.length - 2] : "";
        return stateCandidate?.includes(stateData.state);
      });
      // Update available count
      stateData.available = countListing?.length || 0;
      return stateData;
    });
    setStates(stateData)
  }, [listingDataAll])

  return (
    <div className={css.equipmentContainer}>
      <div className={css.equipmentWrapper}>
        <h2>Find equipment by state</h2>
        <div className={css.statesList}>
          {states.map((item, index) => (
            <NamedLink
              className={css.statesHome}
              name="SearchPage"
              params={{ address: "testing" }}
              to={{ search: stringify({ address: item.state,bounds: decodeURIComponent(item.bound) }) }}

            >
              <div className={css.stateItem} key={index}>
                <strong>{item.state}</strong>
                <p>{item.available} Available</p>
              </div>
            </NamedLink>

          ))}
        </div>
      </div>
    </div>
  );
};

export default EquipmentByState;
